import React from "react";
import Layout from "../utils/layout";
import Divider from "../components/atoms/Divider";
import CanadaFlag from "../../static/CanadaFlag.png";
import USFlag from "../../static/USFlag.png";
import GlobeFlag from "../../static/globe.png";
import Button from "../components/atoms/Button";

export default () => {
  return (
    <Layout>
      <div className="supportDiv">
        <h1 className="ministry__title">Support Share Bibles!</h1>
        <div className="ministry__divider">
          <Divider />
        </div>
        <div>
          <p>
            The Share Bibles ministry is run 100% by volunteers. The only costs
            that we have are associated with the running of the technology: App
            store expenses, database expenses (storage and transactions), and
            website hosting expenses.
          </p>
          <p>Thank you for investing in this kingdom-advancing tool!</p>
        </div>
        <div className="donations">
          <div className="countryDonationOption">
            <img src={CanadaFlag} height={75} className="appstoreLogo" />
            <p>Canadian Donations</p>
            <p className="taxDeductible">(Tax Deductible)</p>
            <a
              className="removeLinkDecoration"
              href="https://m.charitableimpact.com/pd8p"
              target="_blank"
            >
              <Button variant="outlined" type="submit" text="Donate" />
            </a>
          </div>
          {/* <div className="countryDonationOption">
            <img src={USFlag} height={75} className="appstoreLogo" />
            <p>U.S. Donations</p>
            <p className="taxDeductible">
              (Tax Deductible through "University of the Nations Kona")
            </p>
            <a
              className="removeLinkDecoration"
              href="http://bit.ly/ShareBibles0410"
              target="_blank"
            >
              <Button variant="outlined" type="submit" text="Donate" />
            </a>
          </div> */}
          <div className="countryDonationOption">
            <img src={GlobeFlag} height={75} className="appstoreLogo" />
            <p>International Donations</p>
            <p className="taxDeductible">(NOT Tax Deductible)</p>
            <a
              className="removeLinkDecoration"
              href="https://www.paypal.com/paypalme/robandcy"
              target="_blank"
            >
              <Button variant="outlined" type="submit" text="Donate" />
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};
